






import { Component, Vue } from 'vue-property-decorator'
import { OrderRequestItem } from '@/models/Order'
@Component({
  name: 'AdminCustomerOrders',
  components: {}

})
export default class AdminCustomerOrders extends Vue {
  private providerOrderItems :OrderRequestItem[] =[]
}
